import {Button, Form, Input, Label, Overlay} from '../core/components';
import {Declarations} from '../core/declarations';
import {DOM} from '../core/dom';
import {execute} from '../core/executable';
import {Profile} from '../core/profile';
import {Repo} from '../core/repository';
import {URIParser} from '../core/uri-parser';

export namespace Header {
  export function onload(): void {
    setupMobileMenu();
    handlePasswordReset();
    Repo.checkAuth(User.setup, Guest.setup);
  }

  function setupMobileMenu(): void {
    const header = DOM.byId('top-header');
    const mobileMenu = DOM.byId('mobile-menu');
    mobileMenu.addEventListener('click', () => {
      if (header.classList.contains('expanded')) {
        header.classList.remove('expanded');
      } else {
        header.classList.add('expanded');
      }
    });
  }

  function handlePasswordReset(): void {
    const passwordResetCode = URIParser.queryParam('code');
    if (passwordResetCode) {
      Overlay.display('Смяна на парола', Form.create('password-reset-form', `
        ${Input.create('password', 'Нова парола', 'password')}
        <div id="password-reset-form-error"></div>
        ${Button.create('password-reset-btn', '<i class="fa-solid fa-floppy-disk"></i>Смени!')}`),
        () => { window.location.href = '/'; }
      );
      DOM.onIdClick('password-reset-btn', (_, elem) => {
        const reset: Repo.PasswordReset = Form.readData('password-reset-form');
        reset.code = passwordResetCode;
        execute(elem, Repo.resetPassword(reset))
            .onSuccess(() => { Form.replaceContent('password-reset-form', Label.warn('Паролата е сменена успешно! Моля влезте в системата.')); })
            .onFailure(400, (_, err) => { Form.handle('password-reset-form', err); })
            .setDefaultFailureListener(() => { Form.setError('password-reset-form', 'Възникна неочаквана грешка'); });
      });
    }
  }

  namespace Guest {
    export function setup(): void {
      DOM.byId('app-nav').innerHTML = `
        <ul>
          <li id="login-btn"><label><i class="fa-solid fa-right-to-bracket"></i>Вход</label></li>
          <li id="sign-up-btn"><label><i class="fa-solid fa-address-book"></i>Регистрация</label></li>
        </ul>`;
      DOM.onIdClick('login-btn', showLogin);
      DOM.onIdClick('sign-up-btn', showRegistration);
    }

    function showLogin(): void {
      Overlay.display('Вход', Form.create('login-form', `
          ${Input.create('username', 'Потребител', 'text')}
          ${Input.create('password', 'Парола', 'password')}
          <div id="login-form-error"></div>
          ${Button.create('login-form-btn', '<i class="fa-solid fa-right-to-bracket"></i>Вход!')}
          <a id="password-reset" class="app-lnk">Забравена парола?</a>`));
      DOM.onIdClick('login-form-btn', (_, elem) => {
        const credentials: Repo.LoginCredentials = Form.readData('login-form');
        execute(elem, Repo.login(credentials))
            .onSuccess(() => { Overlay.close(); User.setup(); })
            .onFailure(400, (_, err) => { Form.handle('login-form', err); })
            .setDefaultFailureListener(() => { Form.setError('login-form', 'Невалиден потребител или парола'); });
      })
      DOM.onIdClick('password-reset', showPasswordReset);
    }

    function showPasswordReset(): void {
      Overlay.display('Забравена парола', Form.create('password-reset-form', `
        ${Input.create('email', 'Е-мейл', 'text')}
        <div id="password-reset-form-error"></div>
        ${Button.create('password-reset-btn', '<i class="fa-solid fa-paper-plane"></i>Изпрати!')}`)
      );
      DOM.onIdClick('password-reset-btn', (_, elem) => {
        const email: Repo.PasswordResetEmail = Form.readData('password-reset-form');
        execute(elem, Repo.triggerPasswordReset(email))
            .onSuccess(() => { Form.replaceContent('password-reset-form', 'Изпратен е е-мейл за възстановяване на паролата!'); })
            .onFailure(400, (_, err) => { Form.handle('password-reset-form', err); })
            .setDefaultFailureListener(() => { Form.setError('password-reset-form', 'Възникна неочаквана грешка'); });
      });
    }
  
    function showRegistration(): void {
      Overlay.display('Регистрация', Form.create('sign-up-form', `
        ${Input.create('username', 'Потребителско име', 'text')}
        ${Input.create('email', 'Е-мейл', 'text')}
        ${Input.create('password', 'Парола', 'password')}
        <div id="sign-up-form-error"></div>
        ${Button.create('register-btn', '<i class="fa-solid fa-paper-plane"></i>Регистрирай!')}`)
      );
      DOM.onIdClick('register-btn', (_, elem) => {
        const data: Repo.Registration = Form.readData('sign-up-form');
        execute(elem, Repo.register(data))
            .onSuccess(() => { Form.replaceContent('sign-up-form', 'Регистрацията е успешна! Ще получите линк за активация на посочения имейл.'); })
            .onFailure(400, (_, err) => { Form.handle('sign-up-form', err); })
            .onFailure(409, () => { Form.setError('sign-up-form', 'Потребителското име или е-майла са заети'); })
            .setDefaultFailureListener(() => { Form.setError('sign-up-form', 'Възникна неочаквана грешка'); });
      })
    }
  }

  namespace User {
    export function setup(): void {
      DOM.byId('app-nav').innerHTML = `
        <ul>
          <li id="declarations-btn"><label><i class="fa-solid fa-file-lines"></i>Декларации</label></li>
          <li id="profile-btn"><label><i class="fa-solid fa-user-gear"></i>Профил</label></li>
          <li id="logout-btn"><label><i class="fa-solid fa-right-from-bracket"></i>Изход</label></li>
        </ul>`;
      DOM.onIdClick('declarations-btn', Declarations.show);
      DOM.onIdClick('profile-btn', Profile.show);
      DOM.onIdClick('logout-btn', () => { Repo.logout().onSuccess(Guest.setup); });
    }
  }
}