import {CommonDeclaration, PayrollViews} from './common_declarations';
import { AccountType, AccountTypes, Declaration, InsuranceType, InsuranceTypes } from './common_repository';
import {Button, DatePicker, Form, Hint, Input, Overlay, Select, Table, Toggle} from './components';
import {DOM} from './dom';
import {execute} from './executable';
import {Repo} from './repository';

export namespace Declarations {
  export function show(): void {
    Repo.getDeclarationMonths().onSuccess(months => {
      Overlay.display('Справки:', `
        <div id="declarations-container">
          ${prepareDeclarationTable(months)}
          ${Button.create('add-declaration-btn', '<i class="fa-solid fa-plus"></i>Нова декл.')}
          ${Button.create('view-payments-btn', '<i class="fa-solid fa-magnifying-glass"></i>Плащания')}
        </div>`);
      DOM.onIdClick('add-declaration-btn', addDeclaration);
      DOM.onIdClick('view-payments-btn', () => { showPayments(); });
      for (const idx in months) {
        DOM.onIdClick('view-1-' + idx, () => { Repo.getPayroll(months[idx]).onSuccess(resp => { PayrollViews.displayPayroll(resp, show); }); });
        DOM.onIdClick('view-2-' + idx, () => { Repo.getPayroll(months[idx]).onSuccess(resp => { PayrollViews.displayPayslip(resp, show); }); });
        DOM.onIdClick('view-3-' + idx, () => { Repo.getPayroll(months[idx]).onSuccess(resp => { PayrollViews.displayRecap(resp, show); }); });
      }
    });
  }

  function addDeclaration(): void {
    ManageDeclaration.show();
  }

  function prepareDeclarationTable(months: string[]): string {
    const tableRows = months.map((m, idx) => [
      m,
      Button.create('view-1-' + idx, '<i class="fa-solid fa-magnifying-glass"></i><label>Преглед</label>'),
      Button.create('view-2-' + idx, '<i class="fa-solid fa-magnifying-glass"></i><label>Преглед</label>'),
      Button.create('view-3-' + idx, '<i class="fa-solid fa-magnifying-glass"></i><label>Преглед</label>'),
      downloadLink(m, 'one'),
      downloadLink(m, 'six')
    ]);
    return Table.create([
      'Дата',
      '<div class="short">Вед.</div><div class="full">Ведомост</div>',
      'Фиш',
      '<div class="short">Рек.</div><div class="full">Рекапитулация</div>',
      '<div class="short">Об.1</div><div class="full">Обр. 1</div>',
      '<div class="short">Об.6</div><div class="full">Обр. 6</div>'],
      tableRows);
  }

  function downloadLink(monthYear: string, path: 'one' | 'six'): string {
    return CommonDeclaration.downloadLink(`users/forms/${path}?monthYear=${monthYear}&pass=${Repo.getPassId()}`, monthYear, path);
  }

  function showPayments(): void {
    CommonDeclaration.showPayments(show, Repo.getPayments, (monthsCount: number) => Repo.addPayment(monthsCount));
  }
}

namespace ManageDeclaration {
  let latestFromRepo: Declaration;

  export function show(): void {
    latestFromRepo = undefined;
    Overlay.display('Социални осигуровки', Form.create('new-declaration-form', `
      <h3>Общи</h3>
      ${Input.create('firstName', 'Име', 'text')}
      ${Input.create('middleName', 'Презиме', 'text')}
      ${Input.create('lastName', 'Фамилия', 'text')}
      ${Input.create('dateOfBirth', 'Дата на раждане', 'text')}
      ${Input.create('personalNumber', 'ЕГН', 'text')}
      ${Toggle.create('hasBulstatReg', 'Юридическо лице', true)}
      ${Hint.create('hasBulstatReg-hint', 'От 4 януари 2022 г. на изменението в закона Агенцията по вписванията служебно са преобразувани от 10-значен в 9-значен код ЕИК на физическите лица.')}
      ${Input.create('companyName', 'Име по БУЛСТАТ', 'text')}
      ${Input.create('bulstat', 'БУЛСТАТ', 'text')}
      <h3>За Месец</h3>
      <div>
        <input type="radio" id="accountTypeSelf" name="accountType" value="SELFEMPLOYED" />
        <label for="accountTypeSelf">${AccountTypes.nameFor('SELFEMPLOYED')}</label>
      </div>
      ${Hint.create('accountTypeSelf-hint', 'за свободна професия, занаятчийска дейност, лица упражняващи дейност като еднолични търговци, собственици или съдружници в търговски дружества')}
      <div>
        <input type="radio" id="accountTypeFarmer" name="accountType" value="FARMER" />
        <label for="accountTypeFarmer">${AccountTypes.nameFor('FARMER')}</label>
      </div>
      ${Hint.create('accountTypeFarmer-hint', 'земеделски производители и тютюнопроизводители, които са регистрирани като самоосигуряващи се лица')}
      ${Input.create('monthYear', 'Месец', 'text')}
      ${Hint.create('monthYear-hint', 'Избира се месеца, за който искате да подадете осигурителна декларация')}
      ${Select.create('insuranceType', 'Вид здравноосигурен', InsuranceTypes.VALUE_TO_NAME)}
      ${Hint.create('insuranceType-hint', 'Има пряка зависимост от подадената декларация за регистрация на самоосигуряващо се лице и избора ви на вид осигурен от програмата, тоест избирате същия вид осигурен както в декларацията подадена в НАП за започване на дейност. Виж пълна инструкция на сайта ни.')}
      ${Input.create('baseSalary', 'Основна работна заплата', 'text')}
      ${Hint.create('baseSalary-hint', 'Базова заплата, на база на която се изчисляват надчисленията - стоиността трябва да е между минималния и максималния осигурителен доход')}
      <h4 id="sick-label">Дни в болнични, бременност и раждане</h4>
      ${Hint.create('sick-hint', 'Отбелязването на болнични, в програмата не отменя задължението ви да подадете вашия болничен лист в НОИ. С попълването на данните в програмата се осигурява коректното отразяване на дните за болничен и майчинство във вашата осигурителна декларация обр.1')}
      ${Input.create('sickFrom1', 'от', 'text', false)}
      ${Input.create('sickTo1', 'до', 'text', false)}
      ${Input.create('sickFrom2', '', 'text', false)}
      ${Input.create('sickTo2', '', 'text', false)}
      <h4 id="maternity-label">Дни в отглеждане на дете до 2 годишна възраст</h4>
      ${Hint.create('maternity-hint', 'ако имате болничен за бременност и раждане или сте в отпуск за гледане на дете до 2 годишна възраст е препоръчително да се обърнете към екипа ни')}
      ${Input.create('maternityFrom', 'от', 'text', false)}
      ${Input.create('maternityTo', 'до', 'text', false)}
      ${Toggle.create('withoutHealthInsurance', 'Лице без здравно осигуряване', false)}
      ${Input.create('activityStartDay', 'Ден на стартиране на дейност', 'text', false)}
      ${Hint.create('activityStartDay-hint', 'Попълват се само датата от месеца, в който сте стартирала дейността. Според попълненото в  декларация за регистрация самоосигуряващо се лице.')}
      ${Input.create('activityEndDay', 'Ден на край на дейност', 'text', false)}
      ${Hint.create('activityEndDay-hint', 'Попълват се само датата от месеца, в който сте прекъснали дейността. Според попълненото в  декларация за регистрация самоосигуряващо се лице.')}
      <div id="new-declaration-form-error"></div>
      ${Button.create('new-declaration-btn', '<i class="fa-solid fa-floppy-disk"></i>Запази!')}`
    ), Declarations.show);
    DatePicker.setup('#dateOfBirth, #sickFrom1, #sickTo1, #sickFrom2, #sickTo2, #maternityFrom, #maternityTo');
    DatePicker.setupNoDay('#monthYear');
    const monthYear: HTMLInputElement = DOM.byId('monthYear');
    monthYear.addEventListener('change', () => {
      reloadForm(monthYear.value);
    });
    const insuranceType: HTMLSelectElement = DOM.byId('insuranceType');
    const withoutHealthInsurance: HTMLInputElement = DOM.byId('withoutHealthInsurance');
    insuranceType.addEventListener('change', toggleInsuranceFieldsVisibility);
    withoutHealthInsurance.addEventListener('change', toggleInsuranceFieldsVisibility);
    DOM.onIdClick('new-declaration-btn', (_, elem) => {
      const data: DeclarationFormData = Form.readData('new-declaration-form');
      execute(elem, Repo.addDeclaration(toRepo(data)))
        .onSuccess(() => { Declarations.show(); })
        .onFailure(400, (_, err) => { Form.handle('new-declaration-form', err); })
        .onFailure(403, (_, err) => { Form.handle('new-declaration-form', err); })
        .setDefaultFailureListener(() => { Form.setError('new-declaration-form', 'Възникна неочаквана грешка'); });
    });
    DOM.byId('accountTypeSelf').addEventListener('change', () => { DOM.byId<HTMLInputElement>('baseSalary').value = latestFromRepo.baseSalarySelf; });
    DOM.byId('accountTypeFarmer').addEventListener('change', () => { DOM.byId<HTMLInputElement>('baseSalary').value = latestFromRepo.baseSalaryFarm; });
    reloadForm(new Date());
  }

  function toggleInsuranceFieldsVisibility(): void {
    const insuranceType: string = DOM.byId<HTMLSelectElement>('insuranceType').value;
    const withoutHealthInsurance: boolean = DOM.byId<HTMLInputElement>('withoutHealthInsurance').checked;
    const shouldHide: boolean = ['DISABLED', 'DISABLED_RETIRED'].includes(insuranceType) || withoutHealthInsurance;
    const ids = ['sick-label', 'sickFrom1-container', 'sickTo1-container', 'sickFrom2-container', 'sickTo2-container', 'maternity-label', 'maternityFrom-container', 'maternityTo-container', 'sick-hint', 'maternity-hint'];
    DOM.toggleClass(ids, shouldHide, 'hidden');
  }

  function reloadForm(date: Date | string): void {
    Repo.getDeclarationForMonth(date).onSuccess(resp => {
      latestFromRepo = resp;
      Form.setData('new-declaration-form', fromRepo(resp));
      toggleInsuranceFieldsVisibility();
    });
  }

  function fromRepo(repo: Declaration): DeclarationFormData {
    return {
      firstName: repo.firstName,
      middleName: repo.middleName,
      lastName: repo.lastName,
      dateOfBirth: repo.dateOfBirth,
      personalNumber: repo.personalNumber,
      hasBulstatReg: 'true',
      companyName: repo.companyName,
      bulstat: repo.bulstat,
      accountType: repo.accountType,
      monthYear: repo.monthYear,
      insuranceType: repo.insuranceType,
      baseSalary: repo.baseSalary,
      sickFrom1: repo.daysSickLeave1 && repo.daysSickLeave1.from,
      sickTo1: repo.daysSickLeave1 && repo.daysSickLeave1.to,
      sickFrom2: repo.daysSickLeave2 && repo.daysSickLeave2.from,
      sickTo2: repo.daysSickLeave2 && repo.daysSickLeave2.to,
      maternityFrom: repo.daysMaternityLeave && repo.daysMaternityLeave.from,
      maternityTo: repo.daysMaternityLeave && repo.daysMaternityLeave.to,
      withoutHealthInsurance: repo.hasHealthInsurance + '' === 'false' ? 'true' : 'false',
      activityStartDay: repo.activityStartDay,
      activityEndDay: repo.activityEndDay
    };
  }

  function toRepo(formData: DeclarationFormData): Declaration {
    return {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      personalNumber: formData.personalNumber,
      companyName: formData.companyName,
      bulstat: formData.bulstat,
      accountType: formData.accountType,
      monthYear: formData.monthYear,
      insuranceType: formData.insuranceType,
      baseSalary: formData.baseSalary,
      daysSickLeave1: {
        id: latestFromRepo && latestFromRepo.daysSickLeave1 && latestFromRepo.daysSickLeave1.id,
        from: formData.sickFrom1,
        to: formData.sickTo1
      },
      daysSickLeave2: {
        id: latestFromRepo && latestFromRepo.daysSickLeave2 && latestFromRepo.daysSickLeave2.id,
        from: formData.sickFrom2,
        to: formData.sickTo2
      },
      daysMaternityLeave: {
        id: latestFromRepo && latestFromRepo.daysMaternityLeave && latestFromRepo.daysMaternityLeave.id,
        from: formData.maternityFrom,
        to: formData.maternityTo
      },
      hasHealthInsurance: formData.withoutHealthInsurance !== 'on',
      activityStartDay: formData.activityStartDay,
      activityEndDay: formData.activityEndDay
    };
  }

  type DeclarationFormData = {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    personalNumber: string;
    hasBulstatReg: string;
    companyName: string;
    bulstat: string;
    accountType: AccountType;
    monthYear: string;
    insuranceType: InsuranceType;
    baseSalary: string;
    sickFrom1: string;
    sickTo1: string;
    sickFrom2: string;
    sickTo2: string;
    maternityFrom: string;
    maternityTo: string;
    withoutHealthInsurance: string;
    activityStartDay: string;
    activityEndDay: string;
  }
}
