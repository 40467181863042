export namespace URIParser {
  export function queryParam(name: string, uri?: string): string {
    let s = uri || location.search;
    s = s && s.includes('?') && s.substring(s.lastIndexOf('?') + 1) || '';
    for (const pair of s.split('&')) {
      const nameValue = pair.split('=');
      if (nameValue.length === 2 && nameValue[0] === name) {
        return nameValue[1];
      }
    }
    return '';
  }
}