import { Ajax } from './ajax';
import { Payments, Payroll } from './common_repository';
import { Button, Label, Overlay, Table } from './components';
import { DOM } from './dom';
import { execute } from './executable';

export namespace CommonDeclaration {
  export function showPayments(onClose: Function, getPayments: () => Ajax.Callbacks<Payments, void>, addPayment: (months: number) => Ajax.Callbacks<void, void>, msg?: string): void {
    const newPaymentBtn = (months: number): string => Button.create('new-payment-' + months, months === 1 ? months + ' месец' : months + ' месеца');
    const newPaymentHandler = (months: number): void => {
      DOM.onIdClick('new-payment-' + months, (_, elem) => {
        execute(elem, addPayment(months))
            .onSuccess(() => { showPayments(onClose, getPayments, addPayment, 'Плащането беше заявено успешно и ще се отрази в оставащи платени месеци след като бъде потвърдено от администратор.')})
            .onFailure(() => { DOM.byId('new-payment-error').innerHTML = Label.warn('Възникна неочаквана грешка')});
      });
    }
    getPayments().onSuccess(data => {
      Overlay.display('Плащания', `
        <div id="payments-container">
          <label>Оставащи платени месеци: <b>${data.remainingPayments}</b></label>
          <h3>Заяви плащане</h3>
          <div id="new-payment-error">${msg ? Label.success(msg) : ''}</div>
          <div id="new-payment-container">
            ${newPaymentBtn(1)}
            ${newPaymentBtn(3)}
            ${newPaymentBtn(6)}
            ${newPaymentBtn(12)}
          </div>
          <h3>Начини за плащане</h3>
          <h4>Банков превод</h4>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-paperclip"></i></span>При: <b>УниКредит Булбанк АД</b></li>
            <li><span class="fa-li"><i class="fas fa-paperclip"></i></span>Бенефициент: <b>"Счети НА" ООД</b></li>
            <li><span class="fa-li"><i class="fas fa-paperclip"></i></span>IBAN: <b>BG27UNCR70001524896135</b></li>
            <li><span class="fa-li"><i class="fas fa-paperclip"></i></span>BIC: <b>UNCRBGSF</b></li>
          </ul>
          <h4>Основание за плащане</h4>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-paperclip"></i></span>Потребителското ви име или имейла, с който сте се регистрирали</li>
          </ul>
          <p>*Възможни са други видове безкасови плащания като PayPal, Easypay, Револют  и други, но трябва да бъдат индивидуално уговорени на <a href="mailto:office@schetitoti.com">office@schetitoti.com</a>. Цените остават същите посочени в „Ценова листа“</p>
          <h3>Изминали плащания</h3>
          ${Table.create(['Заявено на', 'Брой месеци', 'Потвърдено на'], data.payments.map(p => [p.declarationDate, p.monthsCount + '', p.confirmationDate || 'Непотвърдено']))}
        </div>`, onClose);
      newPaymentHandler(1);
      newPaymentHandler(3);
      newPaymentHandler(6);
      newPaymentHandler(12);
    });
  }

  export function downloadLink(path: string, monthYear: string, formNumber: 'one' | 'six'): string {
    const fileName = formNumber === 'six' ? 'NRA62007.txt' : resolveFormOneFileName(monthYear);
    return `
      <a href="/api/${path}" download="${fileName}">
        ${Button.create(undefined, '<i class="fa-solid fa-floppy-disk"></i><label>Сваляне</label>')}
      </a>`;
  }

  function resolveFormOneFileName(monthYear: string): string {
    var result = monthYear.match(/^\d{1,2}\/(\d{4})$/);
    var year = parseInt(result[1]);
    return year <= 2020 ? "EMPL2019.txt" : "EMPL2021.txt";
  }
}

export namespace PayrollViews {
  export function displayRecap(recap: Payroll, onClose: Function): void {
    function cellFn(text, extraCss = '', colspan = 1): string     { return `<td colspan="${colspan}" style="background-color: #FFF; padding: 0.25rem; min-width: 6rem; ${extraCss}">${text}</td>`; }
    function cellUlFn(text, extraCss = ''): string                { return cellFn(text, `border-bottom: 1px double #000; border-collapse: collapse; ${extraCss}`); }
    function cellUlOlFn(text): string                             { return cellUlFn(text, `border-top: 1px solid #000;`); }
    function cellBoldFn(text, extraCss = ''): string              { return cellFn(text, `font-weight: bold; ${extraCss}`); }
    function cellBoldUlFn(text): string                           { return cellBoldFn(text, `border-bottom: 1px solid #000; border-collapse: collapse;`); }

    const sickRow = recap.zoSickPercent !== 0 || recap.sickSalary !== 0 || recap.zoSickAmount !== 0 ? `<tr>${cellFn('Здравна осиг. в/у болнични - собственик')}${cellFn(recap.zoSickPercent + "%")}${cellFn(recap.sickSalary)}${cellFn(recap.zoSickAmount)}${cellFn(561111)}</tr>` : '';
    const title = recap.companyName && recap.bulstat ? `${recap.companyName} с Булстат ${recap.bulstat}` : `${recap.fullName} с ЕГН: ${recap.personalNumber}`;
    const table = `
      <table style="margin-left: auto; margin-right: auto; border: 1px solid transparent; border-collapse: collapse; font-family: 'Arial Narrow', sans-serif; text-align: left;">
        <caption style="height: 1.8rem; margin: 0; font-size: 1.2rem; line-height: 1.8rem; font-weight: bold; text-align: center; vertical-align: middle; background-color: #FFF;">Рекапитулация</caption>
        <tr><td colspan="5" style="background-color: #FFF; padding: 0.25rem; font-weight: bold; font-style: italic; text-align: center">на ${title}</td></tr>
        <tr><td colspan="5" style="background-color: #FFF; padding: 0.25rem; font-weight: bold; font-style: italic; text-align: center; border-bottom: 2px solid #147; border-collapse: collapse;">за месец ${recap.monthYear}</td></tr>
        <tr>${cellFn('', '', 4)}${cellBoldFn('Отдел')}</tr>
        <tr>${cellBoldUlFn('Данъци и осигуровки - РЕКАПИТУЛАЦИЯ')}${cellFn('ставка')}${cellFn('база')}${cellFn('сума')}${cellFn(0)}</tr>
        <tr>${cellFn('Вноски за сметка на работодател')}${cellFn('', '', 4)}</tr>
        <tr>${cellFn('', '', 2)}${cellBoldFn('Всичко:')}${cellUlOlFn('0.00')}${cellFn('')}</tr>
        <tr>${cellFn('   ', '', 5)}</tr>
        <tr>${cellUlFn('Лични вноски на персонала')}${cellFn('', '', 4)}</tr>
        <tr>${cellFn('ДОО и всички осиг. случай - собственик / след 1960 г')}${cellFn(recap.dooPercent + "%")}${cellFn(recap.salary)}${cellFn(recap.dooAmount)}${cellFn(551111)}</tr>
        <tr>${cellFn('ДЗПО-УПФ или доп.вн. за ф.Пенсии - собственици род. след')}${cellFn(recap.dzpoPercent + "%")}${cellFn(recap.salary)}${cellFn(recap.dzpoAmount)}${cellFn(581111)}</tr>
        <tr>${cellFn('Здравна осиг. - собственик')}${cellFn(recap.zoPercent + "%")}${cellFn(recap.salary)}${cellFn(recap.zoAmount)}${cellFn(561111)}</tr>
        ${sickRow}
        <tr>${cellFn('', '', 2)}${cellBoldFn('Всичко:')}${cellUlOlFn(recap.totalDeduction)}${cellFn('')}</tr>
        <tr>${cellFn('   ', '', 5)}</tr>
        <tr>${cellFn('')}${cellFn('Счетоводител:')}${cellFn('')}${cellFn('Управител')}${cellFn('')}</tr>
      </table>`;
    display('Рекапитулация:', table, onClose);
  }

  export function displayPayslip(payslip: Payroll, onClose: Function): void {
    const tableIfo = [
      payslip.fullName,
      payslip.yearOfBirth,
      "Вид осигурен",
      "12",
      "Самоосигуряващ се",
      "Възнаграждение за месец:",
      payslip.monthYear,
      "Работни дни:",
      payslip.daysWorking,
      "Трудов стаж гг/мм/дд",
      "00г 00м 00д",
      "% за труд. възн. за стаж",
      "0.00%",
      "Длъжност",
      "Самоосигуряващ се",
      "", "",
      "Основна работна заплата",
      payslip.baseSalary,
      "Осигурителен доход",
      payslip.salary,
      "Отработени дни за месеца",
      payslip.daysWorking,
      "Данъчна основа",
      "",
      "Дневно работно време /ч./",
      "",
      "Минимален осиг. доход",
      payslip.minSalary,
      "НАЧИСЛЕНИЯ",
      "дни",
      "",
      "УДРЪЖКИ",
      "",
      "Възнаграждение за отработени дни", 
      payslip.daysWorking,
      "0.00",
      "Фондове ДОО " + payslip.dooPercent + "%",
      payslip.dooAmount,
      "Платен отпуск",
      "0",
      "0.00",
      "Ф. Пенсии в/у соц. р/ди",
      "0.00",
      "Социални разходи",
      "-",
      "0.00",
      "ДЗПО-УПФ или Ф.Пенси " + payslip.dzpoPercent + "%",
      payslip.dzpoAmount,
      "Трудово възнагражд. за стаж",
      "0",
      "0.00",
      "Здр. осиг. вноска " + payslip.zoPercent + "%",
      payslip.zoTotalAmount,
      "Трудово възнагражд. за болнични",
      "0",
      "0.00",
      "Неплатен отпуск",
      "",
      "Болнични и бременност",
      "0",
      payslip.daysSick,
      "ДОД по чл.42 ЗДДФЛ",
      "0.00",
      "Отглеждане на дете до 2 год.",
      "0",
      payslip.daysMaternity,
      "Данъчни облекчения",
      "0.00",
      "Други възнаграждения облагаеми",
      "-",
      "0.00",
      "", "", "", "", "",
      "Месечен аванс",
      "0.00", "", "", "",
      "Доброволни удръжки",
      "0.00",
      "Други необлагаеми",
      "-",
      "0.00",
      "", "",
      "Суми, в/у които се дължи ДОД",
      "",
      "0.00",
      "", "",
      "Всичко начисления",
      "0.00",
      "Всичко удръжки",
      "0.00",
      " ", " ", " ", " ",
      "Изплатени в брой",
      "-" + payslip.totalDeduction,
      "Подпис",
      "............"];

    const table = `
      <table style="margin-left: auto; margin-right: auto; border: 1px solid transparent; border-collapse: collapse; font-family: 'Arial Narrow', sans-serif; text-align: left;">
        <caption style="height: 1.8rem; margin: 0; font-size: 1.2rem; line-height: 1.8rem; font-weight: bold; text-align: center; vertical-align: middle; background-color: #FFF; border-bottom: 2px solid #147; border-collapse: collapse;">Ведомост за заплати:</caption>
        <tr style="font-weight: bold;">
          ${tableIfo.slice(0, 4).map((t, i) => `<td style="background-color: #FFF; padding: 0.25rem; min-width: 6rem;" ${i === 1 ? 'colspan="2"' : ''}>${t}</td>`).join('')}
        </tr>
        <tr style="font-weight: bold;">
          <td style="background-color: #FFF; padding: 0.25rem; min-width: 6rem;" colspan="5">${tableIfo[4]}</td>
        </tr>
        <tr style="font-weight: bold;">
          ${tableIfo.slice(5, 9).map((t, i) => `<td style="height: 4rem; background-color: #FFF; padding: 0.25rem; min-width: 6rem;" ${i === 6 ? 'colspan="2"' : ''}>${t}</td>`).join('')}
        </tr>
        ${[0, 1, 2, 3, 4].map(j => {
          return `<tr>${tableIfo.slice(4*j+9, 4*j+13).map((t, i) => `<td style="background-color: #FFF; padding: 0.25rem; min-width: 6rem;" ${i === 4*j + 10 ? 'colspan="2"' : ''}>${t}</td>`).join('')}</tr>`;
        }).join('')}
        <tr style="font-weight: bold; border-top: 2px solid #147; border-bottom: 2px solid #147; border-collapse: collapse;">
          ${tableIfo.slice(29, 34).map(t => `<td style="background-color: #FFF; padding: 0.25rem; min-width: 6rem;">${t}</td>`).join('')}
        </tr>
        ${[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(k => {
          return `<tr>${tableIfo.slice(5*k+34, 5*k+39).map((t, i) => `<td style="background-color: #FFF; padding: 0.25rem; min-width: 6rem; ${i+5*k+34 === 67 || i+5*k+34 === 68 ? 'border-top: 2px solid #147; border-bottom: 2px solid #147; border-collapse: collapse; font-style: italic;' : ''}">${t}</td>`).join('')}</tr>`;
        }).join('')}
        ${[0, 1, 2].map(l => {
          return `<tr style="font-weight: bold; border-top: 2px solid #147; border-bottom: 2px solid #147; border-collapse: collapse;">
              ${tableIfo.slice(4*l+94, 4*l+98).map((t, i) => `<td style="background-color: #FFF; padding: 0.25rem; min-width: 6rem;" ${i === 4*l + 95 ? 'colspan="2"' : ''}>${t}</td>`).join('')}
            </tr>`;
        }).join('')}
      </table>`;

    display('Фиш:', table, onClose);
  }

  export function displayPayroll(payroll: Payroll, onClose: Function): void {
    //Основна част на таблицата:
    var tableIfo = ["№", 
      "Име, Презиме, Фамилия",
      "Вид осигурен",
      "Година на раждане",
      "Име на компанията",
      "БУЛСТАТ",
      "Длъжност",
      "Отдел",
      "Трудов стаж години",
      "гг/мм/дд",
      "Минимален осигурителен доход",
      "Основна работна заплата",
      "Отработени дни за месеца",
      "Дневно работно време /часа/",
      "Основно възнаграждение",
      "Дни в неплатен отпуск",
      "Редовен и допълнителен годишен отпуск",
      "дни",
      "сума",
      "Социални разходи, в/у които се дължат осигуровки",
      "% за доп. труд. възн. за придобит труд. стаж и проф. опит /за година/",
      "Доп. трудови възнаграждения за придобит трудов стаж и проф. опит",
      "Трудово възнаграждение за болнични от работодател",
      "дни",
      "сума",
      "Допълнителни възнаграждения облагаеми",
      "№",
      "ВСИЧКО ТРУДОВО ВЪЗНАГРАЖДЕНИЕ",
      "Дни в болнични за с/ка на ДОО",
      "Дни в отгл.на дете за с/ка на ДОО",
      "Други необлагаеми трудови възнаграждения",
      "БРУТНО ТРУДОВО ВЪЗНАГРАЖДЕНИЕ",
      "Осигурителен доход",
      "Фондове ДОО " + payroll.dooPercent + "%",
      "Фондове ДОО в/у социaлни разходи 0%",
      "Лице без здравно осигуряване",
      "Здравно осигуряване " + payroll.zoPercent + "% / " + payroll.zoSickPercent + "%",
      "ДЗПО - УПФ или допълн. вноска за ф. Пенсии " + payroll.dzpoPercent + "%",
      "Данъчни облекчения",
      "Суми, върху които се дължи само ДОД",
      "СУМА ЗА ОБЛАГАНЕ С ДОД",
      "ДОД",
      "Доброволни удръжки",
      "Общо удръжки",
      "Чиста сума за получаване",
      "Авансово плащане",
      "Подпис за получен аванс",
      "Остатък за получаване",
      "Подпис"];

    var userInfo = ["1",
      payroll.fullName,
      "12",
      payroll.yearOfBirth,
      payroll.companyName,
      payroll.bulstat,
      "Самоосигуряващ се",
      "",
      "00г 00м 00д",
      payroll.minSalary,
      payroll.baseSalary,
      payroll.daysWorking,
      "",
      payroll.salary,
      "",
      "",
      "",
      "",
      "",
      "0.00",
      "",
      "",
      "",
      payroll.salary,
      payroll.daysSick,
      payroll.daysMaternity,
      "",
      payroll.salary,
      payroll.salary,
      payroll.dooAmount,
      "0.00",
      payroll.hasHealthInsurance ? "не" : "да",
      payroll.zoTotalAmount,
      payroll.dzpoAmount,
      "",
      "",
      "0.00",
      "0.00",
      "",
      payroll.totalDeduction,
      "0.00",
      "",
      "",
      "0.00",
      ""];

    const baseCellStyle = 'background-color: #FFF; padding: 4px; min-width: 20px; border: 1px solid #000; border-collapse: collapse;';
    const midCellStyle = 'background-color: #FFF; padding: 4px; min-width: 20px; height: 30px; text-align: center; border-bottom: 1px solid #000; border-collapse: collapse;';
    const tr = (content: string, extraCss: string = ''): string => `<tr style="border: 1px solid #000; border-collapse: collapse; ${extraCss}">${content}</tr>`;
    const cell = (content, extraCss: string = ''): string => `<td style="${baseCellStyle} ${extraCss}">${content}</td>`;
    const cellRowspan = (content, rowspan: number): string => `<td rowspan="${rowspan}" style="${baseCellStyle}">${content}</td>`;
    const cellColspan = (content, colspan: number): string => `<td colspan="${colspan}" style="${baseCellStyle}">${content}</td>`;
    const midCell = (content: string, colspan: 11 | 12): string => `<td colspan="${colspan}" style="${midCellStyle}">${content}</td>`;
    const verticalText = (content: string, height: 190 | 215): string => `<span style="writing-mode: vertical-rl; -ms-writing-mode: tb-rl; display: block; height: ${height}px;">${content}</span>`;

    const table = `
      <table style="margin-left: auto; margin-right: auto; border: 1px solid #000; border-collapse: collapse; font-family: 'Arial Narrow', sans-serif; text-align: left; font-size: 14px;">
        <caption style="margin: 0; font-size: 14px; font-weight: bold; text-align: center; background-color: #FFF; border-bottom: 1.5px solid #147; border-collapse: collapse;">РАЗЧЕТНО ПЛАТЕЖНА ВЕДОМОСТ:</caption>
        ${tr(
          tableIfo.slice(0, 8).map(t => `<td rowspan="2" style="background-color: #FFF; padding: 3px; border: 1px solid #000; border-collapse: collapse;">${verticalText(t, 215)}</td>`).join('')
          + cell(verticalText(tableIfo[8], 190))
          + tableIfo.slice(10, 16).map(t => cellRowspan(verticalText(t, 215), 2)).join('')
          + cellColspan(verticalText(tableIfo[16], 190), 2)
          + tableIfo.slice(19, 22).map(t => cellRowspan(verticalText(t, 215), 2)).join('')
          + cellColspan(verticalText(tableIfo[22], 190), 2)
          + tableIfo.slice(25, 26).map(t => cellRowspan(verticalText(t, 215), 2)).join('')
        )}
        ${tr([9, 17, 18, 23, 24].map(idx => cell(tableIfo[idx])).join(''))}
        ${tr(userInfo.slice(0, 23).map((_, i) => cell(i+1)).join(''))}
        ${tr(userInfo.slice(0, 23).map(t => cell(t)).join(''))}
        ${tr(
          cell('')
          + cell('Общо', 'text-align: right;')
          + ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', payroll.salary, '-', '-', '0.00', '0.00', '-', '0.00', '-', '0.00', '0.00'].map(t => cell(t)).join('')
        )}
        ${tr(midCell(`Работни дни: ${payroll.daysWorking}`, 12) + midCell(`месец: ${payroll.monthYear}`, 11), 'height: 25px;')}
        ${tr(
          tableIfo.slice(26, 33).map(t => cellRowspan(verticalText(t, 215), 2)).join('')
          + cellColspan('ЛИЧНИ ОСИГУРОВКИ', 5)
          + tableIfo.slice(38, 49).map(t => cellRowspan(verticalText(t, 215), 2)).join('')
        )}
        ${tr(tableIfo.slice(33, 38).map(t => cell(verticalText(t, 190))).join(''))}
        ${tr(cell(1) + userInfo.slice(23, 45).map((_, i) => cell(i+24)).join(''))}
        ${tr(cell(1) + userInfo.slice(23, 45).map(t => cell(t)).join(''))}
        ${tr(
          ['', payroll.salary, '-', '-', '0.00'].map(t => cell(t)).join('')
          + userInfo.slice(27, 31).map(t => cell(t)).join('')
          + ['-', payroll.zoTotalAmount, payroll.dzpoAmount, '0.00', '0.00', '0.00', '0.00', '0.00', payroll.totalDeduction, '0.00', '0.00', '', '0.00', ''].map(t => cell(t)).join('')
        )}
      </table>`;

    display('Ведомост:', table, onClose);
  }

  function display(title: string, content: string, onClose: Function): void {
    const overlayContent = `
      <div id="declaration-view">
        <div id="print-container">
          <div id="print_me" style="width: 100%; text-align: left; vertical-align: middle; min-width: 1000px;">
            ${content}
          </div>
        </div>
        ${Button.create('print-btn', '<i class="fa-solid fa-print"></i>Принтирай!')}
      </div>`;
    Overlay.display(title, overlayContent, onClose);
    DOM.onIdClick('print-btn', () => {
      const newWin= window.open("", "PRINT");
      newWin.document.write('<html><body>' + DOM.byId('print_me').outerHTML + "</body></html>");
      newWin.document.close();
      newWin.focus();
      setTimeout(function () { newWin.print(); }, 500);
      newWin.onfocus = function () { setTimeout(function () { newWin.close(); }, 500); };
    });
  }
}