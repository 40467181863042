import {Ajax} from './ajax';

const LOADING_PROGRESS = '<p class="x-loading-progress x-loading">Зареждане</p>';

export function execute<T, R>(elem: HTMLElement, executableCallback: Ajax.Callbacks<T, R>): Ajax.Callbacks<T, R> {
  if (!elem.innerHTML) {
    elem.innerHTML = LOADING_PROGRESS;
  } else {
    elem.classList.add('x-loading');
  }
  return executableCallback.minExecMs(500)
    .setDefaultFailureListener(() => { elem.innerHTML === LOADING_PROGRESS && (elem.innerText = 'Проблем при зареждане'); })
    .onComplete(() => { elem.classList.remove('x-loading'); });
}