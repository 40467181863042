export type FieldError = { fieldName: string; fullPath: string; message: string }
export type Payments = { remainingPayments: number; payments: Payment[]; }
export type Payment = { userId: number; monthsCount: number; id: number; declarationDate: string; confirmationDate: string; }
export type CommonUser = { id: number; username: string; email: string; regTime: string; firstName: string; middleName: string; lastName: string; dateOfBirth: string; }

export type Payroll = {
  monthYear: string;
  fullName: string;
  personalNumber: string;
  companyName: string;
  bulstat: string;
  yearOfBirth: number;
  minSalary: number;
  baseSalary: number;
  daysWorking: number;
  salary: number;
  daysSick: number;
  daysMaternity: number;
  dooPercent: number,
  dooAmount: number;
  hasHealthInsurance: boolean;
  zoPercent: number;
  zoSickPercent: number;
  sickSalary: number;
  zoAmount: number;
  zoSickAmount: number;
  zoTotalAmount: number;
  dzpoPercent: number;
  dzpoAmount: number;
  totalDeduction: number;
}

export type DeclarationDaysRange = { id: number; from: string; to: string; }
export type Declaration = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  personalNumber: string;
  companyName: string;
  bulstat: string;
  accountType: AccountType;
  monthYear: string;
  insuranceType: InsuranceType;
  baseSalary: string;
  baseSalarySelf?: string;
  baseSalaryFarm?: string;
  daysSickLeave1: DeclarationDaysRange;
  daysSickLeave2: DeclarationDaysRange;
  daysMaternityLeave: DeclarationDaysRange;
  hasHealthInsurance: boolean;
  activityStartDay: string;
  activityEndDay: string;
}

type ValueName = {
  value: string;
  name: string;
}

export type AccountType = 'SELFEMPLOYED' | 'FARMER';
export namespace AccountTypes {
  export const VALUE_TO_NAME: ValueName[] = [
    {value: 'SELFEMPLOYED', name: 'Самоосигуряващо се лице'},
    {value: 'FARMER',       name: 'Земеделски производител'}
  ];

  export function nameFor(insuranceType: AccountType): string {
    const entry = VALUE_TO_NAME.find(e => e.value === insuranceType);
    return entry ? entry.name : '';
  }
}

export type InsuranceType = 'DISABLED' | 'REGULAR' | 'DISABLED_RETIRED' | 'RETIRED';
export namespace InsuranceTypes {
  export const VALUE_TO_NAME: ValueName[] = [
    {value: 'DISABLED',         name: 'Нисък процент - без право на болнични'},
    {value: 'REGULAR',          name: 'Висок процент - всички рискове'       },
    {value: 'DISABLED_RETIRED', name: 'Пенсионер - само здравно'             },
    {value: 'RETIRED',          name: 'Пенсионер - висок процент'            }
  ];

  export function nameFor(insuranceType: InsuranceType): string {
    const entry = VALUE_TO_NAME.find(e => e.value === insuranceType);
    return entry ? entry.name : '';
  }
}