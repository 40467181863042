import flatpickr from 'flatpickr';
import * as monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
import { FieldError } from './common_repository';
import {DOM} from './dom';

export namespace Overlay {
  export function display(title: string, htmlContent: string, onClose: Function = () => {}): void {
    close();
    const closeCurrent = () => { close(); onClose(); };
    document.body.appendChild(DOM.toElem(`
      <div id="overlay">
        <div id="overlay-container">
          <div id="overlay-header">
            <h2>${title}</h2>
            <i id="close" class="fa-solid fa-xmark"></i>
          </div>
          <div id="overlay-content">
            ${htmlContent}
          </div>
        </div>
      </div>`));
    DOM.onIdClick('overlay-content', ev => { ev.stopPropagation(); });
    DOM.onIdClick('close', closeCurrent);
    DOM.onIdClick('overlay', closeCurrent);
    document.body.classList.add('disable-scroll');
  }

  export function close(): void {
    document.body.classList.remove('disable-scroll');
    const currentOverlay = DOM.byId('overlay');
    if (currentOverlay) {
      currentOverlay.remove();
    }
  }
}

export namespace Button {
  export function create(id: string, label: string): string {
    const idAttr = id ? ` id="${id}"` : '';
    return `<button${idAttr} type="button" class="app-btn">${label}</button>`;
  }
}

export namespace Input {
  export function create(name: string, label: string, type: 'text' | 'password', isRequired: boolean = true): string {
    return `
      <div id="${name}-container" class="input-container">
        <label for="${name}">${label}${isRequired ? '<i>*</i>' : ''}</label>
        <input id="${name}" name="${name}" type="${type}"${isRequired ? ' required' : ''} />
        <div id="${name}-error"></div>
      </div>`;
  }

  export function setError(name: string, msg: string): boolean {
    const elem = DOM.byId(name + '-error');
    if (!elem) {
      return false;
    }
    elem.innerHTML = Label.warn(msg);
    return true;
  }

  export function clearError(name: string): void {
    const elem = DOM.byId(name + '-error');
    if (elem) {
      elem.innerHTML = '';
    }
  }
}

export namespace Select {
  export function create(name: string, label: string, options: {value: string, name: string}[]): string {
    return `
      <div id="${name}-container" class="select-container">
        <label for="${name}">${label}</label>
        <select id="${name}" name="${name}">
          ${options.map(o => `<option value="${o.value}">${o.name}</option>`).join('')}
        </select>
      </div>`;
  }
}

export namespace Table {
  export function create(headers: string[], rows: string[][]): string {
    const mapCells = (cells: string[]): string => cells.map(c => `<div class="cell">${c}</div>`).join('');
    const mapRows = (): string => rows.map(r => `<div class="row">${mapCells(r)}</div>`).join('');
    return `
      <div class="table">
        <div class="header">${mapCells(headers)}</div>
        ${mapRows()}
      </div>`;
  }
}

export namespace Toggle {
  export function create(id: string, label: string, isOn: boolean): string {
    return `
      <div id="${id}-container" class="toggle-container">
        <label>${label}</label>
        <label class="toggle"><input id="${id}" name="${id}" type="checkbox" ${isOn ? 'checked' : ''} /><span class='slider'></span></label>
      </div>`;
  }
}

export namespace Label {
  export function warn(msg: string): string {
    return `<label class="app-lbl warn">${msg}</label>`;
  }

  export function success(msg: string): string {
    return `<label class="app-lbl success">${msg}</label>`;
  }
}

export namespace DatePicker {
  export function setup(selector: string): void {
    flatpickr(selector, {
      disableMobile: true,
      altInput: true,
      altFormat: 'Y-m-d',
      dateFormat: 'm/d/Y'
    });
  }

  export function setupNoDay(selector: string): void {
    flatpickr(selector, {
      disableMobile: true,
      altInput: true,
      plugins: [
        (monthSelectPlugin as any)({
          shorthand: true,
          altFormat: 'Y-m',
          dateFormat: 'm/Y'
        })
      ]
    });
  }

  export function isDatePicker(elem: HTMLElement): boolean {
    return (elem as any)._flatpickr;
  }

  export function setDate(elem: HTMLElement, val: any): void {
    ((elem as any)._flatpickr as flatpickr.Instance).setDate(val);
  }
}

export namespace Form {
  export function create(id: string, content: string): string {
    return `<form id="${id}" class="app-form">${content}</form>`;
  }

  export function setError(formId: string, errorMsg: string): void {
    const formError = DOM.byId(formId + '-error');
    if (formError) {
      formError.innerHTML = Label.warn(errorMsg);
    }
  }

  export function setData(formId: string, vals: {[name: string]: any}): void {
    const form: HTMLFormElement = DOM.byId(formId);
    for (const name in vals) {
      const elem: HTMLInputElement = form.elements[name];
      if (elem) {
        if (elem.type === 'checkbox') {
          elem.checked = vals[name] === 'true';
        } else if (DatePicker.isDatePicker(elem)) {
          DatePicker.setDate(elem, vals[name]);
        } else {
          elem.value = vals[name];
        }
      }
    }
  }

  export function handle(formId: string, errors: FieldError[] | FieldError): void {
    clearErrors(formId);
    let generalErrors = '';
    const handleError = (err: FieldError): void => {
      if (!err.fieldName || !Input.setError(err.fieldName, err.message)) {
        generalErrors += Label.warn(err.message);
      }
    }
    if (Array.isArray(errors)) {
      for (const err of errors) {
        handleError(err);
      }
    } else {
      handleError(errors);
    }
    if (generalErrors) {
      setError(formId, generalErrors);
    }
  }

  export function replaceContent(formId: string, content: string): void {
    const form = DOM.byId(formId);
    if (form) {
      form.innerHTML = content;
    }
  }

  export function readData<T>(id: string): T {
    const form: HTMLFormElement = DOM.byId(id);
    const data = {};
    new FormData(form).forEach((value, name) => {
      data[name] = value;
    });
    return data as T;
  }

  function clearErrors(formId: string): void {
    const form: HTMLFormElement = DOM.byId(formId);
    for (let i = 0; i < form.elements.length; ++i) {
      const elem = form.elements.item(i);
      Input.clearError(elem.id);
    }
    const formError = DOM.byId(formId + '-error');
    if (formError) {
      formError.innerHTML = '';
    }
  }
}

export namespace Hint {
  export function create(id: string, content: string): string {
    return `
      <div id="${id}" class="app-hint">
        <i class="fa-regular fa-circle-question hint-icon"></i>
        <div class="hint-content">${content}</div>
      </div>`;
  }
}