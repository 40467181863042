import { Button, Label, Overlay, Toggle } from './components';
import { DOM } from './dom';
import { Repo } from './repository';

export namespace Profile {
  export function show(): void {
    Repo.getProfileInfo().onSuccess(profile => {
      Overlay.display('Профил', `
        <div id="user-profile">
          <h3>Е-мейл предупреждения</h3>
          ${Toggle.create('notifications', 'за декларации', profile.hasNotifications)}
          <h3>Преглед на налични данни</h3>
          ${renderUserInfo(profile.user)}
          ${Button.create('close-account-btn', '<i class="fa-solid fa-trash-can"></i>Изтриване на профила')}
        </div>`);
      DOM.onIdClick('close-account-btn', () => {
        Repo.triggerAccountClose().onSuccess(() => {
          DOM.byId('close-account-btn').remove();
          DOM.byId('user-profile').appendChild(DOM.toElem(Label.warn('Заявката за затваряне на профила е успешна. Ще получите линк за потвърждение на имейла си, което необратимо ще изтрие регистрацията Ви и всички данни свързани с нея от "СчеТИто ТИ!"')));
        });
      });
      const notifications: HTMLInputElement = DOM.byId('notifications');
      notifications.onchange = () => { Repo.toggleNotifications({hasNotifications: notifications.checked}); };
    });
  }

  function renderUserInfo(user: Repo.ProfileUser): string {
    return `
      <div class="grid-2">
        <label>Идентификатор</label><span>${user.id}</span>
        <label>Потребителско име</label><span>${user.username}</span>
        <label>Е-майл</label><span>${user.email}</span>
        <label>Регистриран на</label><span>${user.regTime}</span>
        <label>Име</label><span>${user.firstName}</span>
        <label>Презиме</label><span>${user.middleName}</span>
        <label>Фамилия</label><span>${user.lastName}</span>
        <label>Дата на раждане</label><span>${user.dateOfBirth}</span>
        <label>ЕГН</label><span>${user.personalNumber}</span>
        <label>Име на фирма</label><span>${user.companyName}</span>
        <label>Булстат</label><span>${user.bulstat}</span>
      </div>`;
  }
}